




















import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {VersionTaskState} from '@/tasks/types/VersionTaskState';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

const MOLECULES = {
  1: '$\\text{5,6-dichlorohex-5-en-3-ol}$',
  2: '$\\text{4-chloro-2-methylbut-3-enal}$',
  3: '$\\text{2,3,5-trimethylhept-3-ene}$',
};

export default defineComponent({
  name: 'UofTQuiz3Question3',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<VersionTaskState>,
      required: true,
    },
  },
  data() {
    return {
      isomerAttachments: [] as File[],
    };
  },
  computed: {
    version(): number {
      return this.taskState.getValueByName('version')?.content?.value ?? 1;
    },
    molecule(): string {
      return MOLECULES[this.version as keyof typeof MOLECULES];
    },
    attachments(): File[] {
      return [...this.isomerAttachments];
    },
  },
});
